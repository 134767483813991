"use client";

import { Metadata } from "next";
import Error500 from "./components/common/errors/Error500";
import { useUpdateIntercom } from "./hooks/useUpdateIntercom";
import "./styles.css";

export const metadata: Metadata = {
  title: "Error | Sourceful",
};

// See: https://nextjs.org/docs/app/api-reference/file-conventions/error#global-errorjs
export default function GlobalError({ reset }: { reset: () => void }) {
  const { hideIntercom } = useUpdateIntercom();

  hideIntercom();

  return (
    <html>
      <head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin={"anonymous"} />
        <link
          href="https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </head>
      <body>
        <Error500 source="app/global-error" reset={reset} />
      </body>
    </html>
  );
}
