export const useUpdateIntercom = () => {
  const toggleIntercom = (hide: boolean) => {
    if (window.Intercom) {
      window.Intercom("update", {
        hide_default_launcher: hide,
      });
    }
  };

  const showIntercom = () => {
    toggleIntercom(false);
  };

  const hideIntercom = () => {
    toggleIntercom(true);
  };

  return {
    showIntercom,
    hideIntercom,
  };
};
