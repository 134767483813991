import { Button, ButtonText, TextBody, TextHeadline } from "@sourceful/design-system-v3";
import SourcefulLogo from "../../header/primary-navigation/SourcefulLogo";

interface Error500Props {
  source?: string;
  reset: () => void;
}

export default function Error500({ source = "", reset }: Error500Props) {
  return (
    <div className="min-h-screen flex flex-row items-center justify-center bg-gradient-br-violet-blue-green">
      <div className="flex flex-col m-24 gap-32 items-center text-center">
        <div className="text-ui-lightest w-168 [&_svg]:w-full [&_svg]:h-auto">
          <SourcefulLogo />
        </div>

        <div className="text-ui-lightest">
          <TextHeadline size="small" asChild>
            <h2 data-error-source={source}>Something went wrong.</h2>
          </TextHeadline>
          <TextBody size="medium" asChild>
            <p>
              We&apos;re unable to resume where we left off, but we&apos;re on it.
              <br />
              Thanks for your patience.
            </p>
          </TextBody>
        </div>

        <div className="flex gap-16 dark">
          <Button
            variant="outline"
            size="md"
            onClick={() => {
              reset();
            }}
          >
            <ButtonText>Try again</ButtonText>
          </Button>
          <Button variant="outline" size="md" asChild>
            <a href="/">
              <ButtonText>Back to shop</ButtonText>
            </a>
          </Button>
          <Button variant="outline" size="md" asChild>
            <a href="https://www.sourceful.com/contact">
              <ButtonText>Contact support</ButtonText>
            </a>
          </Button>
        </div>
      </div>
    </div>
  );
}
